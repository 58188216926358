import React, { ReactNode } from "react";
import CookieConsent from "react-cookie-consent";
import { Button, ButtonShape } from "../forms";
import { loadAnalyticsProviders } from "../../utils/consent";
import { googleTag } from "../../utils/analytics";

interface Props {
  children: ReactNode;
  className?: string;
  onClick(): void;
}

const cookieName = "timber-cookie-consent";

const NoticeButton: React.FC<Props> = ({ children, className, onClick }) => {
  const isDeclineButton = className?.includes("decline");
  return (
    <Button
      className="px-16"
      filled={!isDeclineButton}
      onClick={onClick}
      shape={ButtonShape.ROUND}
    >
      {children}
    </Button>
  );
};

export const CookieNotice: React.FC = () => {
  const handleAccept = () => {
    googleTag("consent", "update", {
      ad_storage: "granted",
      analytics_storage: "granted",
      ad_personalization: "granted",
      ad_user_data: "granted",
    });

    loadAnalyticsProviders();
  };

  const handleDecline = () => {
    googleTag("consent", "update", {
      ad_storage: "denied",
      analytics_storage: "denied",
      ad_personalization: "denied",
      ad_user_data: "denied",
    });
  };

  return (
    <CookieConsent
      cookieName={cookieName}
      location="bottom"
      disableStyles
      enableDeclineButton
      buttonText="Nõustun"
      declineButtonText="Keeldun"
      flipButtons
      containerClasses="container bg-white fixed sm:mb-8 sm:pl-14 pl-10 py-8 pr-10 sm:flex items-center justify-center shadow-lg rounded-2xl"
      contentClasses="mb-4 lg:mb-0 sm:mr-4 text-lg"
      buttonWrapperClasses="flex flex-col gap-2"
      declineButtonClasses="decline"
      ButtonComponent={NoticeButton}
      onAccept={handleAccept}
      onDecline={handleDecline}
      overlay
      overlayClasses="fixed bg-black bg-opacity-40 inset-0 z-20 flex justify-center"
    >
      See veebisait kasutab küpsiseid analüüsi ja turunduse eesmärkidel.
      Nõustudes annate loa küpsiste kasutamiseks.{" "}
      <a
        target="_blank"
        href="https://timber.ee/kasutustingimused"
        className="text-green underline"
        rel="noreferrer"
      >
        Loe lähemalt
      </a>
    </CookieConsent>
  );
};
