import { getCookieConsentValue } from "react-cookie-consent";
import { loadFacebookPixel, loadGtag } from "./analytics";

export const handleInitialClientLoad = () => {
  const hasAlreadyConsented =
    getCookieConsentValue("timber-cookie-consent") === "true";
  loadGtag(hasAlreadyConsented ? "granted" : "denied");
  if (hasAlreadyConsented) {
    loadAnalyticsProviders();
  }
};

export const loadAnalyticsProviders = () => {
  loadFacebookPixel();
};
