/* eslint-disable */
// @ts-nocheck

const gtmSnippet = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-K4BXFWM');`;

export const loadGtag = (defaultConsentValue? = "denied") => {
  if (typeof window.gtag === "undefined") {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }

    window.gtag = gtag;

    gtag("consent", "default", {
      ad_storage: defaultConsentValue,
      ad_user_data: defaultConsentValue,
      ad_personalization: defaultConsentValue,
      analytics_storage: defaultConsentValue,
    });

    const script = document.createElement("script");
    script.innerText = gtmSnippet;
    document.head.appendChild(script);
  }
};

export const loadFacebookPixel = () => {
  if (typeof window.fbq === "undefined") {
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      "script",
      "https://connect.facebook.net/en_US/fbevents.js"
    );
    fbq("init", "307190202988932");
    fbq("track", "PageView");
  }
};

export const googleTag = (...args: any[]) => {
  if (typeof window.gtag === "function") {
    window.gtag(...args);
  }
};

/* eslint-enable */
